import { MiddlewareAPI } from "redux";
import { IUserState } from "../../redux/types/IUserState";

const ENVIRONMENT_DEVELOPMENT = "development";
const ENVIRONMENT_PRODUCTION = "production";

let cachedStore: MiddlewareAPI;
export const attachReduxStore = (store: MiddlewareAPI) => (cachedStore = store);

declare let switches: { environment?: string };

const getWebpackEnvironment = (): string => switches.environment;
const getBackendEnvironment = (): string => {
    if (cachedStore) {
        const user: IUserState = cachedStore.getState().user;
        return user.environment;
    }
};
export const isDebug = (): boolean =>
    getWebpackEnvironment() === ENVIRONMENT_DEVELOPMENT;

const matchBackendEnvironment = (environment: string): boolean =>
    getBackendEnvironment() === environment;

const matchDevelopment = isDebug;

const matchNonProduction = (): boolean =>
    isDebug() || !matchBackendEnvironment(ENVIRONMENT_PRODUCTION);

const matchProduction = (): boolean =>
    !isDebug() && matchBackendEnvironment(ENVIRONMENT_PRODUCTION);

const matchNone = (): boolean => false;
const matchAny = (): boolean => true;

export const AGENT_SHOW_AGENT_TAB = matchDevelopment;
export const AGENT_SHOW_FEEDBACK = matchNonProduction;
export const BILLING_SHOW_LICENSES = matchDevelopment;
export const LOG_TO_CONSOLE = matchDevelopment;
export const LOG_ESTIMATION_TO_CONSOLE = matchNonProduction;
export const I18N_DEBUG_MODE = matchNone;
export const I18N_STORE_KEYS_TO_VARIABLE = matchDevelopment;
export const GHS_SHOW_CODES = matchNonProduction;
export const GRAPH_SHOW_MISSING_DATA_WARNINGS = matchDevelopment;
export const GRAPH_USE_PATTERNS = matchDevelopment;
export const OPTIONS_SHOW_OPTIONS = matchNonProduction;
export const OPTIONS_SHOW_WEIGHTS = matchNonProduction;
export const PREDICTOR_SHOW_CHEMPREDICTOR = matchNonProduction;
export const PREDICTOR_USE_WEBSOCKETS = matchAny;
export const PROPERTIES_DISPATCH_ESTIMATION = matchNone;
export const PROPERTIES_ESTIMATION_SHOW_ALERT_ERRORS = matchDevelopment;
export const PROPERTIES_HIGHLIGHT_EXPERIMENTAL_COLOR = matchAny;
export const PROPERTIES_HIDE_EMPTY_ROWS = matchAny;
export const PROPERTIES_SHOW_AGENT = matchNonProduction;
export const PROPERTIES_SHOW_ESTIMATED_IF_EXPERIMENTAL = matchAny;
export const PROPERTIES_SHOW_CATEGORIES = matchNonProduction;
export const PROPERTIES_SHOW_FEEDBACK = matchAny;
export const PROPERTIES_SHOW_NONLOG_PROPERTIES = matchDevelopment;
export const PROPERTIES_SHOW_SUBPROPERTIES = matchAny;
export const PROPERTIES_SHOW_SUBPROPERTIES_RANK = matchDevelopment;
export const PROPERTIES_SHOW_NMR_PREDICTION = matchNonProduction;
export const PROPERTIES_SHOW_METABOLITE_PREDICTION = matchNonProduction;
export const PROPERTIES_SCORE_WEIGHTS_USE_DROPDOWNS = matchAny;
export const REACTIONS_SHOW_REACTION_ANALYZER = matchDevelopment;
export const SEARCH_RESULTS_SHOW_RELEVANCE = matchDevelopment;
export const SEARCH_RESULTS_ALWAYS_SHOW_SELECTOR = matchNone;
export const SEARCH_ADVANCED_SHOW_FUNCTIONAL_USE = matchAny;
export const SEARCH_ADVANCED_SHOW_DRUG_IMPURITY = matchAny;
export const STRUCTURE_DRAW_USE_SMILES_TEXT_INPUT = matchNonProduction;
export const STRUCTURE_DRAW_USE_KETCHER = matchNonProduction;
export const STRUCTURE_DRAW_SHOW_SIMILARITY_SUBSTRUCTURE = matchNonProduction;
export const VIRIDISCHEM_ADMIN_SHOW_ADMIN_TAB = matchAny;
