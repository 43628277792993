import { alert } from "../../components/global/alert/Alert";
// tslint:disable-next-line:no-submodule-imports
import { all, put, select, takeEvery } from "redux-saga/effects";
import {
    LOG_ESTIMATION_TO_CONSOLE,
    PROPERTIES_DISPATCH_ESTIMATION,
    PROPERTIES_ESTIMATION_SHOW_ALERT_ERRORS,
} from "../../components/utilities/FeatureFlags";
import { IRsaaApiErrorAction } from "../../components/utilities/ReduxUtils";
import ViridisUtils from "../../components/utilities/ViridisUtils";
import { IChemicalModel } from "../../data/ChemicalModel";
import t from "../../i18n/i18next";
import {
    estimateCompound,
    loadCompounds,
    searchCompounds,
} from "../actions/pocketbook";
import { getUserLicenseInfo } from "../actions/user";
import { ILoadCompoundsResponse } from "../reducers/pocketbook";
import { IUserFeature, IUserState, userHasFeature } from "../types/IUserState";

interface EstimationResponse {
    models: IChemicalModel[];
    taskClass: string;
    totalSeconds: number;
}

function* logEstimationResults(action) {
    if (!LOG_ESTIMATION_TO_CONSOLE()) {
        return;
    }
    const response: EstimationResponse = action.payload;
    const guid = response.models[0].guid;
    const compoundName = response.models[0].name;
    const taskClass = response.taskClass;
    const elapsedSeconds = response.totalSeconds;
    // tslint:disable-next-line no-console
    ViridisUtils.log(
        `Estimation: ${guid} ${compoundName} ${taskClass} ${elapsedSeconds}s`
    );

    yield null;
}

function* dispatchSearchEstimation(action) {
    yield dispatchGetUserInfo();

    if (PROPERTIES_DISPATCH_ESTIMATION()) {
        const user: IUserState = yield select((state) => state.user);
        if (
            !userHasFeature(user, IUserFeature.ChemicalEstimation) ||
            !userHasFeature(user, IUserFeature.ChemicalSearchEstimationDispatch)
        ) {
            return;
        }

        const response: ILoadCompoundsResponse = action.payload;

        yield all(
            response.models.map((m) =>
                put(
                    estimateCompound.action({
                        guid: m.guid,
                        compoundId: m.id,
                        smiles: m.smiles,
                        type: "Pocketbook",
                        clearCompounds: false,
                        useCache: true,
                    })
                )
            )
        );
    }
}

function* dispatchGetUserInfo() {
    yield put(getUserLicenseInfo.action());
}

function* estimationAlertError(action) {
    if (!PROPERTIES_ESTIMATION_SHOW_ALERT_ERRORS()) {
        return;
    }
    const response: EstimationResponse = action.payload;
    const guid = response.models[0].guid;
    const compoundName = response.models[0].name;
    yield alert(`${guid} ${compoundName}: ${t(action.type)}`);
}

function* responseAlertError(action: IRsaaApiErrorAction<string>) {
    yield alert(t(action.payload.response.response));
}

function* pocketbookSaga() {
    yield all([
        takeEvery(searchCompounds.type_error, responseAlertError),
        takeEvery(estimateCompound.type_request, dispatchGetUserInfo),
        takeEvery(estimateCompound.type_update, logEstimationResults),
        takeEvery(estimateCompound.type_error, estimationAlertError),
        takeEvery(loadCompounds.type_success, dispatchSearchEstimation),
        takeEvery(loadCompounds.type_error, responseAlertError),
    ]);
}

export default pocketbookSaga;
